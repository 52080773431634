<marquee *ngIf="showBanner" class="banner">
  This is a test environment. Do not use for client projects! Production
  environment is:
  <a href="https://eqosphere.quantis.solutions"
    >https://eqosphere.quantis.solutions</a
  >
</marquee>
<mat-toolbar *ngIf="status$ | async as status">
  <a routerLink="/">
    <mat-icon svgIcon="eqosphere-logo" class="logo"></mat-icon>
  </a>
  <div fxFlex="row" fxLayout="start center" class="menu-container">
    <button
      *ngFor="let button of status.menuButtons"
      mat-button
      color="primary"
      routerLinkActive="active"
      [routerLink]="[button.linkTo]"
      class="menu"
    >
      {{ button.label }}
    </button>
    <span fxFlex></span>
    <a
      *ngIf="isInternal$ | async"
      mat-button
      color="primary"
      [matMenuTriggerFor]="helpUs"
      fxLayout="row"
      fxLayoutAlign="start center"
      class="menu help-us"
    >
      <mat-icon>waving_hand</mat-icon>
      Help us improve eQosphere!
    </a>
    <mat-menu #helpUs="matMenu">
      <button mat-menu-item (click)="openSynonymsFile()">
        Suggest synonyms
      </button>
      <button mat-menu-item (click)="openFeedback()">Give feedback</button>
    </mat-menu>
    <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
      <button
        *ngIf="status.showBasket"
        mat-mini-fab
        [class.basic]="basketSize === 0"
        [color]="basketSize > 0 ? 'primary' : undefined"
        aria-label="Basket"
        [matBadge]="basketSize > 0 ? basketSize : null"
        [matMenuTriggerFor]="basketMenuToolbar"
        matTooltip="Basket"
      >
        <mat-icon>shopping_cart</mat-icon>
      </button>
      <mat-menu #basketMenuToolbar="matMenu">
        <button mat-menu-item [routerLink]="['/basket']">
          <mat-icon>list</mat-icon>
          View
        </button>
        <button mat-menu-item (click)="openImportDialog()">
          <mat-icon>file_upload</mat-icon>Import
        </button>
        <button
          mat-menu-item
          (click)="openExportDialog()"
          [disabled]="basketSize === 0"
        >
          <mat-icon>file_download</mat-icon>
          Export
        </button>
        <button
          mat-menu-item
          (click)="openUpgradeDialog()"
          [disabled]="basketSize === 0"
        >
          <mat-icon>upgrade</mat-icon>
          Upgrade
        </button>
      </mat-menu>
      <button
        mat-mini-fab
        class="basic"
        [color]="undefined"
        aria-label="Documentation"
        [matMenuTriggerFor]="docMenuToolbar"
        matTooltip="Documentation"
      >
        <mat-icon>description</mat-icon>
      </button>
      <mat-menu #docMenuToolbar="matMenu">
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/eQosphere_usermanual_v1-4_20240926.pdf"
          target="_blank"
          mat-menu-item
        >
          User manual
        </a>
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/eqosphere_datasets_list.xlsx"
          target="_blank"
          mat-menu-item
        >
          List of datasets
        </a>
        <button mat-menu-item (click)="openChangelog()">Changelog</button>
        <a
          *ngIf="isExternal$ | async"
          href="mailto:eqosphere.support@quantis.com"
          target="_blank"
          mat-menu-item
        >
          Contact helpdesk
        </a>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="wfldb">WFLDB</button>
        <button mat-menu-item [matMenuTriggerFor]="dryad">DRYAD</button>
      </mat-menu>
      <mat-menu #wfldb="matMenu">
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/wfldb_methodological_guidelines.pdf"
          target="_blank"
          mat-menu-item
        >
          Methodological guidelines
        </a>
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/wfldb_documentation.pdf"
          target="_blank"
          mat-menu-item
        >
          Documentation
        </a>
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/wfldb_changelog.pdf"
          target="_blank"
          mat-menu-item
        >
          Changelog
        </a>
      </mat-menu>
      <mat-menu #dryad="matMenu">
        <a
          href="https://prod-public-docs-bucket.s3.eu-west-1.amazonaws.com/dryad_methodological_report.pdf"
          target="_blank"
          mat-menu-item
        >
          Methodological report
        </a>
      </mat-menu>
      <button
        mat-mini-fab
        color="primary"
        aria-label="User profile"
        [matMenuTriggerFor]="userMenuToolbar"
        matTooltip="User"
      >
        {{ initials$ | async }}
      </button>
      <mat-menu #userMenuToolbar="matMenu">
        <button mat-menu-item *ngIf="isExternal$ | async" (click)="changePwd()">
          Change password
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </span>
  </div>
</mat-toolbar>
