import {Injectable} from '@angular/core';
import {DatasetDto} from '@app/api/__generated__/model/datasetDto';
import {DatasetFacetDto} from '@app/api/__generated__/model/datasetFacetDto';
import {DatasetSearchResultDto} from '@app/api/__generated__/model/datasetSearchResultDto';
import {DatasetSearchResultsDto} from '@app/api/__generated__/model/datasetSearchResultsDto';
import {ImpactIndicatorSampleDto} from '@app/api/__generated__/model/impactIndicatorSampleDto';
import {AbstractMapper} from '@app/modules/core/mappers/abstract-mapper';
import {IndicatorMapperService} from '@app/modules/dataset/mappers/indicator/indicator-mapper.service';
import {
  DatasetSearchResult,
  DatasetSearchResults,
  Facet,
  Suggestion,
} from '@app/modules/dataset/models/dataset-search.model';
import {Dataset, ImpactAssessmentSample} from '../../models/dataset.model';
import {DatasetDescriptionMapperService} from '../dataset-description/dataset-description-mapper.service';
import {AssessmentMapperService} from '@app/modules/dataset/mappers/assessment/assessment-mapper.service';
import {BucketKey} from '@app/modules/dataset/services/dataset-search-mapper.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetSearchResultMapperService extends AbstractMapper<
  DatasetSearchResultDto,
  DatasetSearchResult
> {
  constructor(
    private readonly ddMapper: DatasetDescriptionMapperService,
    private readonly assessmentMapper: AssessmentMapperService,
    private readonly indMapper: IndicatorMapperService
  ) {
    super();
  }

  fromResults(dto: DatasetSearchResultsDto): DatasetSearchResults {
    const results = this.fromList(dto.results);
    const facets = this.mapFacets(dto.facets);
    const suggestions: Suggestion[] = dto.suggestions;
    const totalDocCount = dto.totalDocCount;
    const maxDocCount = dto.maxDocCount;
    return { results, facets, suggestions, totalDocCount, maxDocCount };
  }

  from(dto: DatasetSearchResultDto): DatasetSearchResult {
    const { id, name, unit, description, groups, impactIndicatorSamples } = dto;
    return new DatasetSearchResult(
      id,
      name,
      unit,
      this.ddMapper.from(description),
      groups,
      this.mapImpactIndicatorSamples(impactIndicatorSamples)
    );
  }

  fromDataset(dataset: Dataset): DatasetSearchResult {
    return new DatasetSearchResult(
      dataset.id,
      dataset.referenceProduct.name,
      dataset.referenceProduct.unit,
      this.ddMapper.from(dataset.description),
      dataset.referenceProduct.assessmentGroups.map((g) => g.name),
      dataset.impactIndicatorSamples
    );
  }

  fromDatasets(datasets: Dataset[]): DatasetSearchResult[] {
    return datasets.map(d => this.fromDataset(d));
  }

  fromDatasetDtoList(datasets: DatasetDto[]): DatasetSearchResult[] {
    return datasets.map((d) => this.fromDatasetDto(d));
  }

  private fromDatasetDto(dataset: DatasetDto): DatasetSearchResult {
    return new DatasetSearchResult(
      dataset.id,
      dataset.referenceProduct.name,
      dataset.referenceProduct.unit,
      this.ddMapper.from(dataset.description),
      this.assessmentMapper.asGroups(dataset.referenceProduct.allocatedQuantities.contributions).map((g) => g.name),
      dataset.impactIndicatorSamples
    );
  }

  private mapFacets(dtos: DatasetFacetDto[]): Facet[] {
    return dtos.map((facetDto) => this.mapFacet(facetDto));
  }

  private mapFacet(facetDto: DatasetFacetDto): Facet {
    return {
      key: facetDto.key as BucketKey,
      buckets: facetDto.buckets,
    };
  }

  private mapImpactIndicatorSamples(
    dto: ImpactIndicatorSampleDto[] | undefined
  ): Array<ImpactAssessmentSample> {
    if (dto == undefined) {
      return [];
    }
    return dto.map((sample) => this.mapImpactIndicatorSample(sample));
  }

  private mapImpactIndicatorSample(
    dto: ImpactIndicatorSampleDto
  ): ImpactAssessmentSample {
    return {
      methodName: dto.methodName,
      indicator: this.indMapper.from(dto.indicator),
    };
  }
}
